import React from "react";
import SectionTitle from "./SectionTitle";


const Contact = (props) => {
    return(
        <section id="contact">

           <div className="container-two">
              <SectionTitle name='Contact' number='4' />
           </div>
           <h4 style={{textAlign:'center'}}> Mail Me at <a className="mailMe" href="mailTo:Altin_Rrahmani@live.com">Altin_Rrahmani@live.com </a></h4>
           <div className={'container-three'}>
              <div>
                 <a href="https://www.linkedin.com/in/altin-rrahmani-3a547a228" target={'_blank'} rel="noreferrer">
                    <svg className='contactsfooter' x="0px" y="0px"
                         viewBox="0 0 382 382"  >
                       <path className={'blue'} d="M347.445,0H34.555C15.471,0,0,15.471,0,34.555v312.889C0,366.529,15.471,382,34.555,382h312.889
                        C366.529,382,382,366.529,382,347.444V34.555C382,15.471,366.529,0,347.445,0z M118.207,329.844c0,5.554-4.502,10.056-10.056,10.056
                        H65.345c-5.554,0-10.056-4.502-10.056-10.056V150.403c0-5.554,4.502-10.056,10.056-10.056h42.806
                        c5.554,0,10.056,4.502,10.056,10.056V329.844z M86.748,123.432c-22.459,0-40.666-18.207-40.666-40.666S64.289,42.1,86.748,42.1
                        s40.666,18.207,40.666,40.666S109.208,123.432,86.748,123.432z M341.91,330.654c0,5.106-4.14,9.246-9.246,9.246H286.73
                        c-5.106,0-9.246-4.14-9.246-9.246v-84.168c0-12.556,3.683-55.021-32.813-55.021c-28.309,0-34.051,29.066-35.204,42.11v97.079
                        c0,5.106-4.139,9.246-9.246,9.246h-44.426c-5.106,0-9.246-4.14-9.246-9.246V149.593c0-5.106,4.14-9.246,9.246-9.246h44.426
                        c5.106,0,9.246,4.14,9.246,9.246v15.655c10.497-15.753,26.097-27.912,59.312-27.912c73.552,0,73.131,68.716,73.131,106.472
                        L341.91,330.654L341.91,330.654z"/>
                    </svg>
                 </a>
              </div>
              <div>
                 <a href="https://github.com/DevKosov" target={'_blank'} rel="noreferrer">
                    <svg className='contactsfooter'
                         viewBox="0 0 512 512">
                       <rect
                          width="512" height="512"
                          rx="15%"
                          className={'blue'}/>
                       <path className={'white'} d="M335 499c14 0 12 17 12 17H165s-2-17 12-17c13 0 16-6 16-12l-1-50c-71 16-86-28-86-28-12-30-28-37-28-37-24-16 1-16 1-16 26 2 40 26 40 26 22 39 59 28 74 22 2-17 9-28 16-35-57-6-116-28-116-126 0-28 10-51 26-69-3-6-11-32 3-67 0 0 21-7 70 26 42-12 86-12 128 0 49-33 70-26 70-26 14 35 6 61 3 67 16 18 26 41 26 69 0 98-60 120-117 126 10 8 18 24 18 48l-1 70c0 6 3 12 16 12z"/></svg>
                 </a>
              </div>
              <div>
                 <a href="https://codepen.io/DevKosov" target={'_blank'} rel="noreferrer">
                    <svg className='contactsfooter'
                         aria-label="CodePen" role="img"
                         viewBox="0 0 512 512">
                       <rect
                          width="512" height="512"
                          rx="15%"
                          className={'blue'}/>
                       <g
                          fill="none"
                          className={'cpen'}
                          strokeWidth="33"
                          strokeLinejoin="round">
                          <path d="M81 198v116l175 117 175-117V198L256 81z"/>
                          <path d="M81 198l175 116 175-116M256 81v117"/>
                          <path d="M81 314l175-116 175 116M256 431V314"/>
                       </g>
                    </svg>
                 </a>
              </div>
           </div>
        </section>
    )
}

export default Contact;